import { projectMixin } from "@/mixins/project/details/projectMixin";

export const loadableProjectMixin = {
  mixins: [projectMixin],
  data() {
    return {
      loadingProject: false
    };
  },
  computed: {
    /**
     * Fully loaded/opened current record's projects
     * @return {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}[]}
     */
    openProjects() {
      console.warn(
        `Abstract computed ${this.$options.name}.openProjects called.`
      );
      return [];
    },
    /**
     * Fully loaded project
     * @return {*}
     */
    projectFull() {
      return this.openProjects?.find(p => p.projectId === this.projectId);
    }
  },
  methods: {
    async setOpenProject(projectId) {
      console.warn(
        `Abstract method ${this.$options.name}.setOpenProject() called. projectId:`,
        projectId
      );
    },

    /**
     * handle event Project Panel Expanded
     * @return {Promise<void>}
     */
    async onProjectPanelExpanded() {
      try {
        this.loadingProject = true;

        await this.setOpenProject(this.project?.projectId ?? -1);

        console.log(
          `${this.$options.name}.onProjectPanelExpanded() this.projectFull`,
          this.projectFull
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingProject = false;
      }
    }
  }
};
