// router
import { createModuleItemContentRouteLocation } from "@/router/solution/solutionRoutes";

// model
import { moduleNames } from "@/model/solution/moduleModel";

// design
import { iconProjects } from "@/design/icon/iconConst";

// mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { routerMixin } from "@/mixins/shared/router/routerMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export const projectNavigatable = {
  mixins: [appMixin, routerMixin, userMixin],
  computed: {
    commandNavigateToProjectIconName() {
      return iconProjects;
    },
    /**
     * compute visibility of Jump To Project command
     * @return {boolean} if true Jump To Project command is visible
     */
    visibleJumpToProject() {
      return this.isAvailableProjectsModule && this.allowedAccessWFProjects;
    }
  },
  methods: {
    /**
     * project Route Location
     * @param {number} projectId project id
     * @return {{name: (String|string), params: {id: (Number|number)}}}
     */
    projectRouteLocation(projectId) {
      return createModuleItemContentRouteLocation(
        moduleNames.Projects,
        projectId
      );
    },

    /**
     * task Route Location
     * @param {number} taskId task id
     * @return {{name: (String|string), params: {id: (Number|number)}}}
     */
    taskRouteLocation(taskId) {
      return createModuleItemContentRouteLocation(moduleNames.Tasks, taskId);
    },

    /**
    /**
     * can Navigate To Project
     * @param {number} projectId project id
     * @return {boolean} true when can Navigate To Project
     */
    canNavigateToProject(projectId) {
      return (
        this.isAvailableProjectsModule &&
        this.allowedAccessWFProjects &&
        this.canNavigateToModuleItemByModuleItemId(
          moduleNames.Projects,
          projectId
        )
      );
    },

    /**
     * command Navigate To Project Label
     * @param {string} projectName project Name
     * @return {string} formatted command Navigate To Project Label
     */
    commandNavigateToProjectLabel(projectName) {
      return `Project: ${projectName ?? "project"}`;
    },

    /**
     * tooltip Navigate To Project Label
     * @param {string} projectName project Name
     * @return {string} formatted tooltip Navigate To Project Label
     */
    tooltipNavigateToProjectLabel(projectName) {
      return `Jump to Project: ${projectName ?? "project"}`;
    }
  }
};
